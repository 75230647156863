// i18next-extract-mark-ns-start whatsapp-payments
import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {SEO} from 'components/SEO';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {IndexBackground as _IndexBackground} from 'components/landings/IndexBackground';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {IndexContent} from 'components/landings/IndexContent';
import {LargeSectionHeader} from 'components/landings/LargeSectionHeader';
import {SignUpButton} from 'components/SignUpButton';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Background as _Background} from 'components/Background';
import {Content} from 'components/Content';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import whatsapp_phone_en from 'images/whatsapp-phone-en.png';
import whatsapp_phone_es from 'images/whatsapp-phone-es.png';
import {SupportLink} from 'components/links/Support';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {Button, ButtonLink} from 'components/Button';
import {FaqsSection} from 'components/FaqsSection';
import check from 'images/check_green.svg';
import {StyledDiv, StyledImg} from 'components/StyledSx';
import {ScreenSizes} from '../../../types/responsive';
import cards from 'images/cards.svg';
import mobile_payment from 'images/mobile_payment.svg';
import clock from 'images/clock.svg';
import bill from 'images/bill.svg';
import lock from 'images/lock.svg';
import modular from 'images/modular.svg';
import pay_by_link_screenshot from 'images/pay-by-link-screenshot.png';
import Bold from 'components/Bold';
import {HomePaymentMethods} from 'components/HomePaymentMethods';
import Link from 'gatsby-link';
import {GoogleReviews} from 'components/GoogleReviews';

const Background = styled(_Background)`
  &:after {
    content: none;
  }
  &:before {
    @media (max-width: 768px) {
      top: -100px;
    }
  }
`;

const IndexBackground = styled(_IndexBackground)`
  &:before {
    transform: rotate(-64deg) matrix(1, 0, 0, 1, 650, 0);
    height: 2800px;
    width: 2200px;
    left: 75%;
    @media (max-width: ${ScreenSizes.lg}) {
      display: none;
    }
  }
`;

const Section3Content = styled(Content)`
  margin-top: 200px;
  display: flex;
  align-items: center;
  gap: 70px;
  @media (max-width: ${ScreenSizes.sm}) {
    margin-top: 0;
  }
  ul {
    margin-left: 0;

    li {
      list-style: none;
    }
  }
`;

const CheckGreen = styled.img`
  margin: 4px 8px -2px 0;
  svg {
    fill: white;
  }
`;

const WhatsappImageWrapper = styled.div`
  display: flex;
  top: 30px;
  left: 0;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  @media (max-width: ${ScreenSizes.lg}) {
    flex-direction: column;
    top: -10px;
    flex: 9;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const WhatsAppPhone = styled.img`
  width: clamp(220px, 100%, 300px);
  margin: 0;
  height: auto;
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const WhatsappPayments: React.FC<PageProps> = (props) => {
  const {t, navigate, language} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const whatsapp_phone = language === 'es' ? whatsapp_phone_es : whatsapp_phone_en;

  const faqs = [
    {
      header: t('In what countries is MONEI’s WhatsApp payment request feature available?'),
      text: t(
        'Currently, WhatsApp payment requests are available to merchants based in Spain and Andorra, for transactions made anywhere in the world.'
      ),
      content: (
        <Trans>
          Currently, WhatsApp payment requests are available to merchants based in Spain and{' '}
          <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>, for
          transactions made anywhere in the world.
        </Trans>
      )
    },
    {
      header: t('Is there a minimum or maximum transaction amount for WhatsApp payment requests?'),
      text: t('The minimum transaction amount is €1.00 and there is no maximum amount.'),
      content: (
        <Trans>The minimum transaction amount is €1.00 and there is no maximum amount.</Trans>
      )
    },
    {
      header: t('Which currencies can I accept via WhatsApp payment requests?'),
      text: t('Accept whatever currencies your business supports.'),
      content: (
        <Trans>
          Accept whatever <SupportLink slug="/articles/360017801897">currencies</SupportLink> your
          business supports.
        </Trans>
      )
    },
    {
      header: t('Can I send WhatsApp payment requests and receipts via MONEI Pay?'),
      text: t(
        'Not yet. But this feature is coming soon! Subscribe to our newsletter to stay informed.'
      ),
      content: (
        <Trans>
          Not yet. But this feature is coming soon!{' '}
          <InternalPageLink slug="subscribe">Subscribe</InternalPageLink> to our newsletter to stay
          informed.
        </Trans>
      )
    },
    {
      header: t('What if my customer doesn’t have WhatsApp?'),
      text: t(
        'If your customer’s phone number is not associated with a WhatsApp account, the payment request and receipt will be sent via SMS or email.'
      ),
      content: (
        <Trans>
          If your customer’s phone number is not associated with a WhatsApp account, the payment
          request and receipt will be sent via SMS or email.
        </Trans>
      )
    },
    {
      header: t('How do Bizum and WhatsApp payment requests and receipts work together?'),
      text: t(
        'Customers who pay via Bizum will automatically receive a receipt via WhatsApp as long as they’re on WhatsApp (otherwise it will be sent via SMS). '
      ),
      content: (
        <Trans>
          Customers who pay via Bizum will automatically receive a receipt via WhatsApp as long as
          they’re on WhatsApp (otherwise it will be sent via SMS).
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        title={t('WhatsApp Payments')}
        description={t(
          'Get paid faster and improve the customer experience with WhatsApp payment requests. No website or WhatsApp business account required. Learn more ››'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile style={{paddingTop: '60px', paddingBottom: '30px'}}>
            <IndexContent maxWidth="660px" sx={{maxWidth: {md: '100%'}, flex: {all: 2, lg: 11}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Get paid faster with WhatsApp payment requests</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                As of 2023, over 2.24 billion people worldwide use WhatsApp each month, making it
                one of the most popular global mobile messenger apps. Reduce friction, speed up the
                payment process, and let customers pay via their favorite messaging app with MONEI’s
                WhatsApp payment request feature.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <WhatsappImageWrapper>
              <WhatsAppPhone src={whatsapp_phone} alt="WhatsApp phone" title="WhatsApp phone" />
            </WhatsappImageWrapper>
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content sx={{marginTop: {all: '90px', md: '20px'}}}>
          <div style={{textAlign: 'center', color: '#ffffff', marginBottom: '80px'}}>
            <Trans parent="h2">The benefits of WhatsApp payments</Trans>
          </div>
          <CardsContainer style={{paddingBottom: '20px'}}>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, margin: 0, width: '95px'}}
                src={cards}
                alt="Recurring payments"
                title="Recurring payments"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Accept more{' '}
                  <Link to="#accept-most-popular-payment-methods-whatsapp">payment methods</Link>{' '}
                  and <SupportLink slug="/articles/360017801897">currencies</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, margin: 0, width: '125px'}}
                src={mobile_payment}
                alt="pay by link"
                title="pay by link"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Send payment requests and receipts via WhatsApp using{' '}
                  <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-55%)', top: 0, margin: 0, width: '90px'}}
                src={clock}
                alt="Tokenization"
                title="Tokenization"
              />
              <CardWrapper>
                <Trans parent="h5">Quickly take digital payments without a website</Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, margin: 0, width: '70px'}}
                src={bill}
                alt="Tokenization"
                title="Tokenization"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Follow transactions in real-time via your user dashboard and set expiration dates
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-52%)', top: 0, margin: 0, width: '60px'}}
                src={lock}
                alt="Tokenization"
                title="Tokenization"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Get built-in payment security —{' '}
                  <InternalPageLink slug="pci-dss">PCI compliance</InternalPageLink>,{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure
                  </BlogLink>
                  , <BlogLink slug="psd2">PSD2</BlogLink>, and <BlogLink slug="sca">SCA</BlogLink>,
                  we’ve got you covered
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-50%)', top: 0, margin: 0, width: '110px'}}
                src={modular}
                alt="Tokenization"
                title="Tokenization"
              />
              <CardWrapper>
                <Trans parent="h5">
                  <SupportLink slug="/articles/360017814717">Customize</SupportLink> your payment
                  page — use your URL, logo, and brand colors
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions
            sx={{
              justifyContent: 'center',
              display: 'flex'
            }}>
            <SignUpButton variant="dark">
              <Trans>Get MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
        </Content>
      </Background>

      <Section3Content>
        <StyledImg
          src={pay_by_link_screenshot}
          alt="Recurring payments phone"
          title="Recurring payments phone"
          sx={{
            width: {all: '550px', lg: '450px'},
            marginBottom: '0',
            display: {all: 'block', md: 'none'},
            borderRadius: '8px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }}
        />
        <StyledDiv>
          <SectionHeader underline>
            <Trans>How MONEI’s WhatsApp payment requests work</Trans>
          </SectionHeader>
          <List>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              <Bold>Step 1.</Bold> From the <Bold>Payments</Bold> tab in your dashboard, click the{' '}
              <Bold>Pay By Link</Bold> button to create a payment.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              <Bold>Step 2.</Bold> Fill in the transaction amount and customer information and send
              the payment link.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              <Bold>Step 3.</Bold> Customer receives the payment request via WhatsApp and clicks{' '}
              <Bold>Pay now</Bold> to complete the transaction.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              <Bold>Step 4.</Bold> Customer selects their preferred payment method, completes the
              transaction, and receives a receipt via WhatsApp.
            </Trans>
          </List>
          <SectionActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              flexWrap: 'wrap',
              justifyContent: {all: 'flex-start', md: 'center'}
            }}>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ButtonLink href="https://support.monei.com/hc/articles/15028355292689">
              <Trans>Learn more</Trans>
            </ButtonLink>
          </SectionActions>
        </StyledDiv>
      </Section3Content>

      <Content>
        <Section column centered sx={{textAlign: 'center', paddingTop: '150px'}}>
          <SectionHeader underline id="accept-most-popular-payment-methods-whatsapp">
            <Trans>Accept the most popular payment methods via WhatsApp payment requests</Trans>
          </SectionHeader>
          <HomePaymentMethods />
          <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
            <Trans>View All Payment Methods</Trans>
          </Button>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '100px'}}
          contentStyle={{maxWidth: {all: '600px', md: '100%'}}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Send payment requests and receipts via WhatsApp today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Get paid faster and improve the customer experience with WhatsApp payment requests.{' '}
              <Bold>Join MONEI now to get started</Bold>.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '21%'
            }}
            src={whatsapp_phone}
          />
        </GradientBox>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>WhatsApp payment requests FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default WhatsappPayments;

const CardImage = styled.img`
  position: absolute;
`;

const CardWrapper = styled.div`
  max-width: 230px;
  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8rem;
  }
`;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "whatsapp-payments"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
